import jazzy from '../../assets/img/pages/partners/jazzy.png';
import jazzyColor from '../../assets/img/pages/partners/jazzy_color.png';
import kfb from '../../assets/img/pages/partners/kfb_color_inactive.png';
import kfbColor from '../../assets/img/pages/partners/kfb_color_active.png';
import sfwl from '../../assets/img/pages/partners/sfwl.svg';
import sfwlColor from '../../assets/img/pages/partners/sfwl-color.svg';
import chalmers from '../../assets/img/pages/partners/chalmers.png';
import chalmersColor from '../../assets/img/pages/partners/chalmers-color.png';
import delta from '../../assets/img/pages/partners/delta.png';
import deltaColor from '../../assets/img/pages/partners/delta-color.png';


import teamMember1 from '../../assets/img/pages/about/people/jens-ahrens.png';
import teamMember2 from '../../assets/img/pages/about/people/marzena-czajkowska.png';
import teamMember3 from '../../assets/img/pages/about/people/daniel-deboy.png';
import teamMember4 from '../../assets/img/pages/about/people/mateusz-glowacki.png';
import teamMember5 from '../../assets/img/pages/about/people/karolina-jaruszewska.png';
import teamMember6 from '../../assets/img/pages/about/people/ana-monte.png';
import teamMember7 from '../../assets/img/pages/about/people/artur-walaszczyk.png';
import teamMember8 from '../../assets/img/pages/about/people/szymon-kucharski.png';
import teamMember9 from '../../assets/img/pages/about/people/marcin-luczak.png';


import gallery1 from '../../assets/img/pages/about/gallery/1.jpg';
import gallery2 from '../../assets/img/pages/about/gallery/2.jpg';
import gallery5 from '../../assets/img/pages/about/gallery/3.jpg';
import gallery4 from '../../assets/img/pages/about/gallery/4.jpg';
import gallery3 from '../../assets/img/pages/about/gallery/5.jpg';
import gallery6 from '../../assets/img/pages/about/gallery/6.jpg';
import gallery7 from '../../assets/img/pages/about/gallery/7.jpg';

export const partnersLogos = [
  {
    bw: kfb,
    color: kfbColor,
    id: 'kfb',
    link: 'https://kfb-acoustics.com/',
  },
  {
    bw: delta,
    color: deltaColor,
    id: 'delta',
    link: 'https://deltasoundworks.com/',
  },
  {
    bw: chalmers,
    color: chalmersColor,
    id: 'chalmers',
    link: 'https://www.chalmers.se/en/',
  },
  {
    bw: sfwl,
    color: sfwlColor,
    id: 'sfwl',
    link: 'https://www.filmschool.lodz.pl/',
  },
  {
    bw: jazzy,
    color: jazzyColor,
    id: 'jazzy',
    link: 'https://unseen.framer.ai/',
  },
];

export const teamPhotos = {
  teamMember1,
  teamMember2,
  teamMember3,
  teamMember4,
  teamMember5,
  teamMember6,
  teamMember7,
  teamMember8,
  teamMember9,
};

export const gallery = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
];
