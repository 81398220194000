import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import modalNames from '../modals/names';
import * as modalActions from '../../actions/modals';

import acoucouLogo from '../../assets/svg/logo_acoucou_white.svg';
import saLogo from '../../assets/svg/spatial-audio-logo-large.svg';
import euLogo from '../../assets/img/eu-founded.png';

import fbIcon from '../../assets/svg/socialIcons/fb-icon-blue.svg';

class Footer extends React.Component {

  getCurrentYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <footer className="footer">
        <div className="cta-section">
          <div className="cta-section-text sign">
            <p className="cta-section-element text-center">
              <FormattedMessage id="footer.cta.p1" />
            </p>
            <p className="cta-section-element text-center">
              <small>
                <FormattedMessage id="footer.cta.p2" />
              </small>
            </p>
          </div>

          <div
            className="btn btn--color-default btn--style-outline"
            onClick={() => this.props.openModal(modalNames.CONTACT)}
          >
            <FormattedMessage id="footer.cta.contact" />
          </div>
        </div>

        <div className="container py-5 text-center text-md-left">
          <div className="row">
            <div
              className={`col-lg-3 col-md-3 offset-md-0 col-sm-6 offset-sm-3 col-8 offset-2 py-4`}
            >
              <img src={saLogo} alt="ACE" className="img-fluid mx-auto logo" />
            </div>
            <div className="col-md py-4 footer-section">
              <div className="footer-section-element">
                <FormattedMessage id="footer.products.title" />
              </div>
              <a href="https://asknow.acoucou.org/" className="footer-section-element">
                <FormattedMessage id="footer.products.asknow" />
              </a>

              <a href="https://acoucou.org/architecture" className="footer-section-element">
                <FormattedMessage id="footer.products.acoucou-architecture" />
              </a>

              <a href="http://ace.acoucou.org" className="footer-section-element">
                <FormattedMessage id="footer.products.ace" />
              </a>

              <a
                href="https://aci.acoucou.org/"
                className="footer-section-element">
                <FormattedMessage id="footer.products.aci" />
              </a>
            </div>
            <div className="col-md py-4 footer-section">
              <div className="footer-section-element">
                <FormattedMessage id="footer.about.title" />
              </div>
              <div
                className="footer-section-element"
                // onClick={toggleWipLesson}
              >
                <FormattedMessage id="footer.about.materials" />
              </div>
              <Link
                className="footer-section-element"
                to="/about"
                // onClick={toggleWip}
              >
                <FormattedMessage id="footer.about.sa" />
              </Link>
            </div>
            <div className="col-md py-4 footer-section">
              <div className="footer-section-element">
                <FormattedMessage id="footer.social.title" />
              </div>
              <a
                href={'https://www.facebook.com/acoucou.org/'}
                className="footer-section-element"
              >
                <FormattedMessage id="footer.social.facebook" />
              </a>
            </div>
            <div className="col-md py-4 footer-section">
              <div className="footer-section-element">
                <FormattedMessage id="footer.legal.title" />
              </div>
              <div className="footer-section-element">
                <FormattedMessage id="footer.legal.use" />
              </div>
            </div>
          </div>
          <div className="row py-2 footer__bottom">
            <div className="col-12 copyright">
              <img src={acoucouLogo} alt="acoucou" className="img-fluid footer__logo" />
              <span>
                <FormattedMessage id="footer.copyright" />
                 <span> {this.getCurrentYear()}</span>
              </span>
              <a
                className="social-bar__icon"
                href="https://www.facebook.com/acoucou.org/"
              >
                <img src={fbIcon} className="social-bar__icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="py-2 footer__eu">
          <div className="container">
            <div className="footer__eu__content">
              <img src={euLogo} className="float-left"
                   alt="Co-founded by the European Union"
              />
              <p>
                <FormattedMessage id={'footer.eu.description'} />
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  openModal: propTypes.func
};

export default withRouter(
  connect(
    null,
    {
      openModal: modalActions.open
    }
  )(Footer)
);
