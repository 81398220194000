
import * as React from 'react';

import './SocialBar.scss';

import fbIcon from '../../assets/svg/socialIcons/fb-icon.svg';

class SocialBar extends React.Component {
    render() {
        return (
            <div className="social-bar d-flex flex-column">
                <a className="social-bar__icon" href="https://www.facebook.com/acoucou.org/">
                    <img src={fbIcon} className="social-bar__icon" />
                </a>
            </div>
        );
    }
}

export default SocialBar;
