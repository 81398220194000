import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { goToAfterLogin } from '../../actions/authActions';

import './Hero.scss';

class Hero extends React.Component {
  materialsOnClick = () => {
    window.location.href = '/course';
  };

  render() {
    return (
      <div className="hero-home">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="display display-4 text-center">
                <span className="hero-home__subtitle">
                  <FormattedMessage id="page.home.hero.text1"/><br/>
                </span>
                <span className="hero-home__title"><FormattedMessage id="page.home.hero.text2"/></span>
                <span className="hero-home__title2"><FormattedMessage id="page.home.hero.text3"/></span>
              </h1>

              <div className="row hero-home__buttons">
                <div className="col-12 col-lg-4 m-auto btn-wrap text-center">
                  <div
                    className="btn btn--style-outline btn--color-default"
                    onClick={() => this.materialsOnClick()}
                  >
                    <FormattedMessage id="page.home.hero.button1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Hero.propTypes = {
  goToAfterLogin: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

export default connect(
  null,
  ({ goToAfterLogin, push })
)(Hero);
