import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import {connect} from 'react-redux';

import * as modalActions from '../../actions/modals';

import circleImg from '../../assets/img/pages/SA_circle.png';
import './Plan.scss';

class Plan extends React.Component {

  materialsOnClick = () => {
    window.location.href = '/course';
  };
  render() {
    return (
      <div className="plan py-5">
        <div className="container">
            <h3 className="display display--dark text-center">
                <FormattedMessage id="page.home.plan.title" />
            </h3>
            <div className="row py-4">
                <div className="col-md-8 offset-md-2">
                    <p>
                      <FormattedHTMLMessage id="page.home.plan.content" />
                    </p>
                </div>
            </div>

            <div className="row py-4 circle">
              <img src={circleImg} alt="circle" />
            </div>

            <div className="row">
              <div className="col-12 col-lg-4 m-auto btn-wrap text-center">
                <div
                  className="btn btn--style-fill btn--color-primary"
                  onClick={() => this.materialsOnClick()}
                >
                  <FormattedMessage id="page.home.hero.button1" />
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

Plan.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default connect(
  null,
  ({
    openModal: modalActions.open,
  })
  )(Plan);
