import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import * as modalActions from '../../actions/modals';
import modalNames from '../../components/modals/names';

import {teamPhotos} from './config';

const team = [
  {
    footer: null,
    id: 'modal.person.info.jens-ahrens',
    name: 'Jens Ahrens',
    picture: teamPhotos.teamMember1,
    position: 'modal.person.info.jens-ahrens.position',
  },
  {
    footer: null,
    id: 'modal.person.info.marzena-czajkowska',
    name: 'Marzena Czajkowska',
    picture: teamPhotos.teamMember2,
    position: 'modal.person.info.marzena-czajkowska.position',
  },
  {
    footer: null,
    id: 'modal.person.info.daniel-deboy',
    name: 'Daniel Deboy',
    picture: teamPhotos.teamMember3,
    position: 'modal.person.info.daniel-deboy.position',
  },
  {
    footer: null,
    id: 'modal.person.info.mateusz-glowacki',
    name: 'Mateusz Głowacki',
    picture: teamPhotos.teamMember4,
    position: 'modal.person.info.mateusz-glowacki.position',
  },
  {
    footer: null,
    id: 'modal.person.info.karolina-jaruszewska',
    name: 'Karolina Jaruszewska',
    picture: teamPhotos.teamMember5,
    position: 'modal.person.info.karolina-jaruszewska.position',
  },
  {
    footer: null,
    id: 'modal.person.info.szymon-kucharski',
    name: 'Szymon Kucharski',
    picture: teamPhotos.teamMember8,
    position: 'modal.person.info.szymon-kucharski.position',
  },
  {
    footer: null,
    id: 'modal.person.info.marcin-luczak',
    name: 'Marcin Łuczak',
    picture: teamPhotos.teamMember9,
    position: 'modal.person.info.marcin-luczak.position',
  },
  {
    footer: null,
    id: 'modal.person.info.ana-monte',
    name: 'Ana Monte',
    picture: teamPhotos.teamMember6,
    position: 'modal.person.info.ana-monte.position',
  },
  {
    footer: null,
    id: 'modal.person.info.artur-walaszczyk',
    name: 'Artur Walaszczyk',
    picture: teamPhotos.teamMember7,
    position: 'modal.person.info.artur-walaszczyk.position',
  },
];

class Team extends React.Component {
  togglePersonModal(person) {
    this.props.openModal(modalNames.TEAM_PERSON_VIEW, person);
  }

  render() {
  return (
    <div className="container">
      <div className="row py-5">
        <div className="col-12 pb-5 overflow-hidden">
          <h4 className="section-heading">
            <FormattedMessage id="page.about.team.title"/>
          </h4>
        </div>
        {team.map((member) => {
          return (
            <div
              key={member.id}
              className="col-12 col-md-6 col-lg-4 py-4"
              onClick={() => this.togglePersonModal(member)}
            >
              <div className="d-flex flex-md-row flex-column team-member">
                <div className="avatar-wrapper pr-0 pr-md-2 mx-auto mx-md-0">
                  <img
                    className="img-fluid rounded-circle avatar"
                    src={member.picture}
                    alt={member.name}
                  />
                  <span className="icon-arrowR avatar-arrow" />
                </div>
                <div className={
                  `data-wrapper
                  d-flex flex-column justify-content-center
                  pl-0 pl-md-2 pt-4 pt-md-0 mx-auto mx-md-0
                  text-center text-md-left`
                }>
                  <b className="about__team-member">{member.name}</b>
                  <div className="position"><FormattedMessage id={member.position} /></div>
                  {
                    member.extraPosition &&
                    <div className="position position--lighten">{member.extraPosition}</div>
                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
  }
}

Team.propTypes = {
  openModal: PropTypes.func,
};

export default connect(
  null,
  ({
    openModal: modalActions.open,
  })
)(Team);
