import * as React from 'react';
import { FormattedMessage } from 'react-intl';

function About() {
  return (
    <div className="container py-5">
            <div className="row">
                <div className="col-12">
                    <h4 className="section-heading py-5">
                        <FormattedMessage id="page.about.about.title" />
                    </h4>
                </div>

                <div className="col-12 offset-sm-2 col-sm-8 text-center text-md-left">
                    <p className="about__text">
                        <FormattedMessage id="page.about.about.p1" />
                    </p>
                  <p className="about__text">
                    <FormattedMessage id="page.about.about.p2" /> <br/><br/>
                  </p>
                </div>
            </div>
        </div>
  );
}

export default About;
