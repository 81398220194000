import * as React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import modalActions from '../../actions/modals';
import modalNames from '../../components/modals/names';

import {gallery} from './config';
import {FormattedMessage} from 'react-intl';

class Gallery extends React.Component {
  render() {
    return (
      <>
        <div className="col-12">
          <h4 className="section-heading py-5">
            <FormattedMessage id="page.about.gallery.title" />
          </h4>
        </div>
      <div className="about-gallery pb-5">
        <div className="about-gallery__item">
          <img
            className="about-gallery__image about-gallery__image--half"
            src={gallery[0]}
            alt=""
            onClick={() => this.props.openModal(modalNames.LIGHTBOX, {slide: 0})}
          />
          <img
            className="about-gallery__image about-gallery__image--half"
            src={gallery[1]}
            alt=""
            onClick={() => this.props.openModal(modalNames.LIGHTBOX, {slide: 1})}
          />
          <img
            className="about-gallery__image about-gallery__image--half"
            src={gallery[2]}
            alt=""
            onClick={() => this.props.openModal(modalNames.LIGHTBOX, {slide: 2})}
          />
          <img
            className="about-gallery__image about-gallery__image--half"
            src={gallery[3]}
            alt=""
            onClick={() => this.props.openModal(modalNames.LIGHTBOX, {slide: 3})}
          />
        </div>
        <div className="about-gallery__item">
          <img
            className="about-gallery__image"
            src={gallery[4]}
            alt=""
            onClick={() => this.props.openModal(modalNames.LIGHTBOX, {slide: 4})}
          />
        </div>
        <div className="about-gallery__item">
          <img
            className="about-gallery__image"
            src={gallery[5]}
            alt=""
            onClick={() => this.props.openModal(modalNames.LIGHTBOX, {slide: 5})}
          />
          <img
            className="about-gallery__image"
            src={gallery[6]}
            alt=""
            onClick={() => this.props.openModal(modalNames.LIGHTBOX, {slide: 6})}
          />
        </div>
      </div>
      </>
    );
  }
}

Gallery.propTypes = {
  openModal: propTypes.func,
};

export default connect(
  null,
  ({
    openModal: modalActions.open,
  })
)(Gallery);
